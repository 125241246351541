import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const validateEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);

      if (!validateEmail(email)) {
        setError(translate(language, 'forgotPassword.invalidEmail'));
        setLoading(false);
        return;
      }

      const response = await axios.post('/api/auth/forgotPassword', {email});
      if (response.status === 200) {
        navigate('/message', {
          state: {
            message: translate(language, 'forgotPassword.checkEmail'),
            buttonText: translate(language, 'forgotPassword.returnToLogin'),
            buttonLink: '/login',
          },
        });
      } else {
        setError(translate(language, 'forgotPassword.error'));
      }
    } catch (error) {
      console.error(error);
      setError(translate(language, 'forgotPassword.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-[80vh] p-5 text-center">
      <div className="font-bold mb-5">
        {translate(language, 'forgotPassword.headerText')}
      </div>
      <div className="font-regular mb-2.5">
        {translate(language, 'forgotPassword.subText')}
      </div>
      {error && <div className="text-red-500">{error}</div>}
      <input
        className="w-full h-10 my-2.5 px-4 border border-gray-300 rounded-[20px] bg-white font-regular"
        type="text"
        placeholder={translate(language, 'forgotPassword.emailPlaceholder')}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <button
          className="w-full h-10 mt-5 px-4 rounded-[20px] bg-[#cdeee3] text-black font-regular cursor-pointer"
          onClick={handleForgotPassword}>
          {translate(language, 'forgotPassword.submit')}
        </button>
      )}
    </div>
  );
};

export default ForgotPassword;
