import ReactGA4 from 'react-ga4';

export const initGA = measurementId => {
  ReactGA4.initialize(measurementId);
};

export const logPageView = () => {
  ReactGA4.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
};

export const logEvent = (category, action, label) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};
