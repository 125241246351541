import Avatar from '@mui/material/Avatar';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import axios from 'axios';
import Cookies from 'js-cookie';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import QRCode from 'qrcode.react';
import TutorialOverlay, {
  getTutorialImages,
} from '../components/TutorialOverlay';
import EditIcon from '@mui/icons-material/Edit';

const GradientQRCode = ({value, size}) => {
  return (
    <div className="relative">
      {/* Define gradient */}
      <svg width="0" height="0">
        <defs>
          <linearGradient
            id="qrCodeGradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%">
            <stop offset="0%" stopColor="#3DDBD1" />
            <stop offset="100%" stopColor="#007291" />
          </linearGradient>
        </defs>
      </svg>

      {/* QR Code with gradient */}
      <QRCode
        value={value}
        size={size}
        bgColor="#FFFFFF"
        fgColor="url(#qrCodeGradient)"
        level="Q"
        includeMargin={false}
        renderAs="svg"
        className="rounded-lg"
      />
    </div>
  );
};

const UserProfile = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [showTutorial, setShowTutorial] = useState(false);
  const [, setTutorialIndex] = useState(0);

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(Cookies.get('userInfo')));
    } catch {
      null;
    }
    const fetchUserInfo = async () => {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
        );
        setUserInfo(JSON.parse(Cookies.get('userInfo')));
      } catch (error) {
        //console.error('Failed to fetch user info:', error);
        // Optionally handle errors, like redirecting to login or showing an error message
      }
    };

    fetchUserInfo();
  }, []);

  const RightArrowIcon = () => (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor"
      />
    </svg>
  );

  const PointsRightArrowIcon = () => (
    <svg height="15" width="15" viewBox="0 0 20 20">
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor"
      />
    </svg>
  );

  const handleLogout = async () => {
    try {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      Cookies.remove('userInfo');
      await axios.post('api/auth/logout'); // Adjust the URL based on your backend setup
      navigate('/login');
      window.location.reload(); // Reload the page to ensure cookies are cleared
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleNavigateToSettings = () => {
    navigate('/settings', {state: {showBackIcon: true}});
  };

  const handleNavigateToPoints = () => {
    navigate('/points', {state: {showBackIcon: true}});
  };

  const handleNavigateToUserGuide = () => {
    setTutorialIndex(0);
    setShowTutorial(true);
  };

  const handleNavigateToTermsPrivacy = () => {
    navigate('/terms-privacy', {state: {showBackIcon: true}});
  };

  const handleTutorialComplete = () => {
    setShowTutorial(false);
    // Any other actions you want to perform when the tutorial is completed
  };

  const tutorialImages = getTutorialImages();

  return (
    <>
      {showTutorial && <TutorialOverlay onComplete={handleTutorialComplete} />}
      <TopNav text={translate(language, 'userProfile.myProfile')} />

      {/* Profile Container */}
      <div className="m-6 mb-5 flex items-start rounded-[20px] bg-white p-5 font-regular shadow-md relative">
        <div className="mr-5 flex items-center justify-center">
          <Avatar
            alt={userInfo.firstName}
            src="/assets/icons/default_avatar.png"
            sx={{width: 60, height: 60, boxShadow: 3}}
          />
        </div>
        <div className="flex flex-col justify-center leading-tight">
          <h2 className="m-0 mb-[5px] text-lg font-semibold text-black">
            {userInfo.firstName + ' ' + userInfo.lastName}
          </h2>
          <p className="m-0 mb-[5px] text-xs text-gray-600">{userInfo.role}</p>
          <p className="m-0 text-xs">ID: {userInfo.shortId}</p>
          <p className="m-0 text-xs">{userInfo.email}</p>
          <p className="m-0 text-xs">{userInfo.phone}</p>
        </div>
        <EditIcon
          className="ml-auto cursor-pointer text-gray-600"
          onClick={() => navigate('/user-edit', {state: {showBackIcon: true}})}
        />
      </div>

      {/* Enhanced QR Code Container */}
      <div className="mx-6 mb-5 rounded-[20px] bg-white p-6 shadow-md">
        <div className="flex flex-col items-center">
          <div className="relative">
            {/* QR Code with padding and border */}
            <div className="p-4 bg-white rounded-lg border-2 border-gray-100">
              <GradientQRCode
                value={userInfo.shortId?.toString() || ''}
                size={150}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Points Container */}
      <div
        onClick={handleNavigateToPoints}
        className="mx-6 mb-[50px] flex flex-col items-center justify-center rounded-[20px] bg-white p-[10px_20px] text-xs font-regular shadow-md cursor-pointer">
        <div className="flex items-center text-xs">
          {translate(language, 'userProfile.points')}
          <PointsRightArrowIcon />
        </div>
        <div className="flex items-center text-lg font-semibold">
          <ReactSVG
            src="/assets/icons/coins.svg"
            className="h-[25px] w-[25px] mr-[5px]"
          />
          {userInfo.points}
        </div>
      </div>

      {/* Settings Container */}
      <div className="mx-6">
        {/* Settings Item */}
        <div
          className="mb-4 flex items-center justify-between cursor-pointer"
          onClick={handleNavigateToSettings}>
          <ReactSVG src="/assets/icons/settings.svg" className="mr-4" />
          <span className="flex-grow text-base font-regular text-black mr-4">
            {translate(language, 'userProfile.settings')}
          </span>
          <RightArrowIcon />
        </div>

        {/* User Guide Item */}
        <div
          className="mb-4 flex items-center justify-between cursor-pointer"
          onClick={handleNavigateToUserGuide}>
          <ReactSVG src="/assets/icons/user_guide.svg" className="mr-4" />
          <span className="flex-grow text-base font-regular text-black mr-4">
            {translate(language, 'userProfile.userGuide')}
          </span>
          <RightArrowIcon />
        </div>

        {/* Terms & Privacy Item */}
        <div
          className="mb-4 flex items-center justify-between cursor-pointer"
          onClick={handleNavigateToTermsPrivacy}>
          <ReactSVG src="/assets/icons/info_profile.svg" className="mr-4" />
          <span className="flex-grow text-base font-regular text-black mr-4">
            {translate(language, 'userProfile.termsPrivacy')}
          </span>
          <RightArrowIcon />
        </div>

        {/* Logout Button */}
        <button
          className="mt-[50px] w-full rounded-lg border border-[#D5D5D5] py-[14px] text-base font-medium text-[#DB5948]"
          onClick={handleLogout}>
          {translate(language, 'userProfile.logout')}
        </button>
      </div>

      <BottomNav />

      {/* Preloading tutorial images */}
      <div className="hidden">
        {tutorialImages.map(src => (
          <img key={src} src={src} alt="tutorial" />
        ))}
      </div>
    </>
  );
};

export default UserProfile;
