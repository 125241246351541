import {useLocation, useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';

const DynamicMessagePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  let message = location.state?.message;
  let buttonText = location.state?.buttonText;
  let buttonLink = location.state?.buttonLink;

  if (!message || !buttonText || !buttonLink) {
    const queryParams = new URLSearchParams(location.search);
    message = message || queryParams.get('message');
    buttonText = buttonText || queryParams.get('buttonText');
    buttonLink = buttonLink || queryParams.get('buttonLink');
  }

  return (
    <>
      <div className="h-screen flex flex-col items-center justify-center -mt-[100px] px-[35px] font-regular">
        <h1
          className="text-lg text-black mb-5 font-regular"
          dangerouslySetInnerHTML={{
            __html: message.replace(/\n/g, '<br />'),
          }}
        />
        <button
          className="mt-[50px] w-full flex items-center justify-center py-[14px] rounded-lg border border-[#d5d5d5] text-white bg-primary font-medium cursor-pointer"
          onClick={() => navigate(buttonLink)}>
          {buttonText || 'Go'}
        </button>
      </div>
      <BottomNav />
    </>
  );
};

export default DynamicMessagePage;
