import React from 'react';
import Slider from 'react-slick';
import VisibilityIcon from '@mui/icons-material/Visibility';

const HomeSlider = ({
  title,
  items,
  loading,
  imageLoading,
  renderLikeIcon,
  language,
  navigate,
  settings,
}) => {
  return (
    <div className="font-semibold text-base">
      <h2 className="text-t1 mb-3">{title}</h2>
      {loading ? (
        <div className="space-y-4">
          {[1, 2, 3].map(i => (
            <div
              key={i}
              className="w-[200px] h-[150px] bg-gray-200 animate-pulse rounded-lg"
            />
          ))}
        </div>
      ) : (
        <div className="-mx-[35px] text-[#1e1e1e]">
          <Slider {...settings}>
            {items.map(item => (
              <div key={item._id} className="pb-8">
                {imageLoading[item._id] ? (
                  <div className="flex justify-center items-center w-[70vw] h-screen bg-gray-100 rounded-[20px] shadow-md relative">
                    <div className="spinner" />
                  </div>
                ) : (
                  <>
                    <img
                      src={item.coverImg[language]}
                      alt={item.title[language]}
                      className="w-[70vw] aspect-[400/275] rounded-[20px] shadow-md cursor-pointer"
                      onClick={() =>
                        navigate(`/article/${item._id}`, {
                          state: {showBackIcon: true},
                        })
                      }
                    />

                    {/* Interaction Overlay */}
                    <div className="flex justify-end -mt-[30px] mb-4 pr-[calc(30vw-70px)] text-white z-10">
                      <div className="flex items-center ml-2.5 z-10">
                        <VisibilityIcon />
                        <span className="ml-1">{item.readUsers.length}</span>
                      </div>
                      <div className="flex items-center ml-2.5 z-10">
                        {renderLikeIcon(item._id)}
                        <span className="ml-1">{item.likedUsers.length}</span>
                      </div>
                    </div>

                    {/* Title */}
                    <div className="w-[70vw] font-regular text-t3 mx-2.5 mt-2">
                      <h3 className="m-0 text-t3">{item.title[language]}</h3>
                    </div>
                  </>
                )}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default HomeSlider;
