import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const generateIdempotencyKey = () => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
};

const RedeemItemDetail = ({isOpen, selectedVoucher, handleClose}) => {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [, setConfirmScroll] = useState('paper');
  const [walletAddress, setWalletAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [quantity] = useState(1);
  const token = localStorage.getItem('token');
  const [isRedeeming, setIsRedeeming] = useState(false);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (confirmOpen) {
      const {current: descriptionElement} = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [confirmOpen]);

  const handleConfirmOpen = scrollType => () => {
    setConfirmOpen(true);
    setConfirmScroll(scrollType);
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        setWalletAddress(addressArray[0]);
        console.log('Connected:', addressArray[0]);
      } catch (err) {
        console.error('Error connecting to MetaMask:', err);
        alert(translate(language, 'redeemItemDetail.errorConnectingMetaMask'));
      }
    } else if (window.web3) {
      const addressArray = await window.web3.eth.getAccounts();
      setWalletAddress(addressArray[0]);
      console.log('Connected:', addressArray[0]);
    } else {
      alert(translate(language, 'redeemItemDetail.installMetaMask'));
    }
  };

  const handleRedeem = async () => {
    setIsRedeeming(true);
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/redeem/purchase/${selectedVoucher._id}`,
        {quantity, walletAddress},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Idempotency-Key': generateIdempotencyKey(),
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.status === 200) {
        const message = `${translate(language, 'redeemItemDetail.itemRedeemedSuccessfully')}:\n${selectedVoucher?.name[language]}`;
        navigate('/message', {
          state: {
            message,
            buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
            buttonLink: '/redeem',
          },
        });
      } else {
        navigate('/message', {
          state: {
            message: translate(language, 'redeemItemDetail.notEnoughPoints'),
            buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
            buttonLink: '/redeem',
          },
        });
      }
    } catch (error) {
      navigate('/message', {
        state: {
          message: translate(language, 'redeemItemDetail.redeemUnavailable'),
          buttonText: translate(language, 'redeemItemDetail.returnToRedeem'),
          buttonLink: '/redeem',
        },
      });
    } finally {
      setIsLoading(false);
      setIsRedeeming(false);
    }
  };

  const disclaimerText = translate(language, 'redeemItemDetail.disclaimer');

  const getAvailableQuantity = () => {
    const leftover = selectedVoucher?.leftover;
    const quota = selectedVoucher?.qoutaPerson;

    // If both are negative, return 'No Limits'
    if (leftover < 0 && quota < 0) return 'No Limits';

    // If one is negative, return the other (if it's positive)
    if (leftover < 0) return quota >= 0 ? quota : 'No Limits';
    if (quota < 0) return leftover >= 0 ? leftover : 'No Limits';

    // Return the minimum of the two values
    return Math.min(leftover, quota);
  };

  return (
    <>
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <CircularProgress className="text-white" />
        </div>
      )}

      {/* Main Modal */}
      <div
        className={`${isOpen ? 'block' : 'hidden'} fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40`}>
        <div
          className={`bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 max-h-[90vh] overflow-y-auto relative ${fontFamilies.bold}`}>
          {/* Header */}
          <div className="p-4">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <CloseIcon />
            </button>
            <h2 className="text-xl font-bold">
              {selectedVoucher?.name?.[language] || ''}
            </h2>
          </div>

          {/* Image Section */}
          {selectedVoucher?.image && (
            <div className="w-full p-4">
              <img
                src={selectedVoucher.image}
                alt={selectedVoucher?.name?.[language]}
                className="w-full h-48 object-cover rounded-lg shadow-md"
              />
            </div>
          )}

          {/* Content */}
          <div className="p-6 space-y-4">
            <div>
              <p className="text-gray-600">
                {selectedVoucher?.description?.[language] || ''}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {(selectedVoucher?.leftover >= 0 ||
                selectedVoucher?.qoutaPerson >= 0) && (
                <div>
                  <h3 className="font-semibold">
                    {translate(language, 'redeemItemDetail.availableToRedeem')}:
                  </h3>
                  <p
                    className={`${
                      getAvailableQuantity() > 10
                        ? 'text-green-500'
                        : 'text-yellow-500'
                    }`}>
                    {getAvailableQuantity()}
                  </p>
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div className="mt-6">
              {selectedVoucher?.type !== 'exchange' ? (
                <button
                  onClick={handleConfirmOpen('paper')}
                  className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary/80 transition-colors">
                  {translate(language, 'redeemItemDetail.redeem')}
                </button>
              ) : walletAddress ? (
                <div className="space-y-4">
                  <p className="break-words text-sm">
                    {translate(language, 'redeemItemDetail.walletConnected')}:{' '}
                    {walletAddress}
                  </p>
                  <button
                    onClick={handleConfirmOpen('paper')}
                    className="w-full bg-primary text-white py-2 px-4 rounded-lg hover:bg-primary/80 transition-colors">
                    {translate(language, 'redeemItemDetail.redeem')}
                  </button>
                </div>
              ) : (
                <button
                  onClick={connectWallet}
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors">
                  {translate(language, 'redeemItemDetail.connectWallet')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Dialog */}
      {confirmOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div
            className={`bg-white rounded-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[90vh] overflow-y-auto ${fontFamilies.bold}`}>
            <div className="p-4 border-b">
              <h2 className="text-xl font-bold">
                {translate(language, 'redeemItemDetail.redeem')}:{' '}
                {selectedVoucher?.name?.[language] || ''}
              </h2>
              <p className="mt-2">
                {translate(language, 'redeemItemDetail.confirmMessage')}
              </p>
            </div>

            <div className="p-6">
              <p className={`text-xs ${fontFamilies.lightItalic}`}>
                {disclaimerText}
              </p>
            </div>

            <div className="p-4 border-t flex justify-end space-x-4">
              <button
                onClick={handleConfirmClose}
                disabled={isRedeeming}
                className={`px-4 py-2 border rounded-lg transition-colors
                  ${
                    isRedeeming
                      ? 'bg-gray-100 cursor-not-allowed'
                      : 'hover:bg-gray-100'
                  }`}>
                {translate(language, 'redeemItemDetail.cancel')}
              </button>
              <button
                onClick={handleRedeem}
                disabled={isRedeeming}
                className={`px-4 py-2 bg-primary text-white rounded-lg transition-colors
                  flex items-center justify-center min-w-[100px]
                  ${
                    isRedeeming
                      ? 'bg-primary/70 cursor-not-allowed'
                      : 'hover:bg-primary/80'
                  }`}>
                {isRedeeming ? (
                  <>
                    <CircularProgress size={20} className="text-white mr-2" />
                    {translate(language, 'redeemItemDetail.processing')}
                  </>
                ) : (
                  translate(language, 'redeemItemDetail.confirm')
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RedeemItemDetail;
