import {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom';
import HomeSlider from '../components/HomeSlider';
import {ReactSVG} from 'react-svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BottomNav from '../Navigation/BottomNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import TutorialOverlay, {
  getTutorialImages,
} from '../components/TutorialOverlay';

function Home() {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [whatsNewItems, setWhatsNewItems] = useState([]);
  const [activityItems, setActivityItems] = useState([]);
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(userInfo.firstTimeLogin);
  const [imageLoading] = useState({});
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(Cookies.get('userInfo')));
    } catch {
      null;
    }
    const isNative = navigator.userAgent.includes('wv');
    sessionStorage.setItem('isNative', isNative ? 'true' : 'false');

    const fcmToken = sessionStorage.getItem('pushNotificationToken');

    if (isNative && fcmToken) {
      sendPushTokenToServer(fcmToken);
    }

    const fetchUserInfo = async () => {
      try {
        // No need to fetch the token from localStorage, as cookies handle it automatically
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          {withCredentials: true}, // Ensure cookies (including the token) are sent with the request
        );

        setUserInfo(JSON.parse(Cookies.get('userInfo')));

        // Set the user info and points from the response
        //setUserInfo(response.data.userInfo);
        //setPoints(response.data.userInfo.points);

        // Check if it's the first time login
        if (response.data.userInfo.firstTimeLogin) {
          setShowTutorial(true);
        }

        // No need to set token or userInfo in localStorage anymore as it's stored in cookies
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();

    if (!isNative) {
      initializePushNotifications();
    }
  }, []);

  useEffect(() => {
    const fetchWhatsNewItems = async () => {
      try {
        const response = await axios.get(`/api/article/list?type=news`);
        setWhatsNewItems(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error fetching new items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchWhatsNewItems();
  }, [navigate]);

  // Function to send the push notification token to the server
  function sendPushTokenToServer(pushNotificationToken) {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/auth/subscribe`,
        {
          isNative: navigator.userAgent.includes('wv'),
          fcmToken: pushNotificationToken,
        }, // Use pushNotificationToken instead of subscription object
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        if (!response.data.success && response.status !== 304) {
          throw new Error('Bad response from server.');
        }
      })
      .catch(error => {
        console.error(
          'Error sending push notification token to server:',
          error,
        );
      });
  }

  // Function to initialize push notifications
  function initializePushNotifications() {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
      return;
    }

    // Check the current Notification permission status
    if (Notification.permission === 'granted') {
      // The user has already granted permission, you can subscribe the user
      subscribeUser();
    } else if (Notification.permission === 'denied') {
      // The user has denied permission
      console.log('User has denied notifications.');
    } else {
      // The user hasn't been asked yet or they have dismissed the prompt
      requestPermission();
    }
  }

  // Function to request notification permission
  function requestPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        // The user granted permission
        subscribeUser();
      } else if (permission === 'denied') {
        // The user denied permission
        console.log('User denied notifications.');
      } else {
        // The user dismissed the permission prompt
        console.log('User dismissed the permission prompt.');
      }
    });
  }

  // Function to subscribe the user to push notifications
  function subscribeUser() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(function (registration) {
        const applicationServerKey = urlB64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY,
        );
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
          })
          .then(function (subscription) {
            console.log('User is subscribed:', subscription);
            sendSubscriptionToServer(subscription);
          })
          .catch(function (err) {
            console.log('Failed to subscribe the user:', err);
          });
      });
    }
  }

  // Helper function to convert VAPID key
  function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // Function to send the subscription object or push token to your server
  function sendSubscriptionToServer(subscription) {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/auth/subscribe`,
        {subscription},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        if (
          !response.data.success &&
          response.status !== 201 &&
          response.status !== 304
        ) {
          throw new Error('Bad response from server.');
        }
      })
      .catch(error => {
        console.error('Error sending subscription to server:', error);
      });
  }

  useEffect(() => {
    const fetchActivityItems = async () => {
      try {
        const response = await axios.get(`/api/article/list?type=activities`);
        setActivityItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchActivityItems();
  }, [navigate]);

  const handleLike = async articleId => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${articleId}`,
      );

      setLikedArticles(prevLikedArticles => [...prevLikedArticles, articleId]);

      // Update the like count in whatsNewItems and activityItems
      setWhatsNewItems(prevItems =>
        prevItems.map(item =>
          item._id === articleId
            ? {...item, likes: (item.likes || 0) + 1}
            : item,
        ),
      );

      setActivityItems(prevItems =>
        prevItems.map(item =>
          item._id === articleId
            ? {...item, likes: (item.likes || 0) + 1}
            : item,
        ),
      );
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async articleId => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${articleId}`,
      );

      setLikedArticles(prevLikedArticles =>
        prevLikedArticles.filter(id => id !== articleId),
      );

      // Update the like count in whatsNewItems and activityItems
      setWhatsNewItems(prevItems =>
        prevItems.map(item =>
          item._id === articleId
            ? {...item, likes: Math.max((item.likes || 0) - 1, 0)}
            : item,
        ),
      );

      setActivityItems(prevItems =>
        prevItems.map(item =>
          item._id === articleId
            ? {...item, likes: Math.max((item.likes || 0) - 1, 0)}
            : item,
        ),
      );
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const renderLikeIcon = articleId => {
    return likedArticles.includes(articleId) ? (
      <FavoriteIcon
        sx={{color: '#3DDBD1'}}
        onClick={() => handleUnlike(articleId)}
      />
    ) : (
      <FavoriteBorderIcon
        sx={{color: '#3DDBD1'}}
        onClick={() => handleLike(articleId)}
      />
    );
  };

  const handleTutorialComplete = () => {
    setShowTutorial(false);
    updateFirstTimeLogin();
  };

  const updateFirstTimeLogin = async () => {
    try {
      await axios.put(`/api/auth/updateUser`, {
        firstTimeLogin: false,
      });
    } catch (error) {
      console.error('Error updating firstTimeLogin status:', error);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    centerMode: true, // Enable center mode
    centerPadding: '40px',
    responsive: [],
  };

  for (let i = 1; i < 10; i++) {
    const breakpointJson = {
      breakpoint: window.innerWidth * i,
      settings: {
        slidesToShow: i,
        slidesToScroll: 1,
        infinite: false,
      },
    };
    settings.responsive[i] = breakpointJson;
  }

  const PointsRightArrowIcon = () => (
    <svg
      height="15" // Adjust the size as needed
      width="15" // Adjust the size as needed
      viewBox="0 0 20 20" // Ensure your viewBox matches the path's coordinates
    >
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor" // This will take the color from the 'color' style of the component
      />
    </svg>
  );

  const handleNavigateToPoints = () => {
    navigate('/points', {state: {showBackIcon: true}});
  };

  const tutorialImages = getTutorialImages();

  return (
    <>
      {showTutorial && (
        <TutorialOverlay
          images={tutorialImages}
          onComplete={handleTutorialComplete}
        />
      )}
      <div className="mx-[35px] mb-0 mt-0 font-regular">
        {/* Header */}
        <div className="fixed left-0 right-0 top-0 z-[1000] m-0 flex w-full items-center justify-between bg-[#f0f0f0] shadow-md">
          <img
            src="/assets/images/logo.png"
            alt="Carbon Point"
            className={`m-[15px] h-[50px] text-2xl text-[#3DDBD1] font-bold ${
              imagesLoaded ? 'opacity-100' : 'opacity-0'
            }`}
            onLoad={() => setImagesLoaded(true)}
          />
          <div className="flex items-center">
            <QrCodeScannerIcon
              className="my-[15px] text-2xl cursor-pointer"
              onClick={() => navigate('/scanQR', {state: {showBackIcon: true}})}
            />
            <NotificationsIcon
              className="my-[15px] mr-[15px] text-2xl cursor-pointer"
              onClick={() =>
                navigate('/notifications', {state: {showBackIcon: true}})
              }
            />
          </div>
        </div>

        <section>
          {/* Intro Container */}
          <div className="mt-[170px] mb-[50px] rounded-[20px] bg-[#3DDBD1] p-[10px_20px] font-regular text-white shadow-md">
            {/* Greeting Container */}
            <div className="mb-[10px] flex flex-row items-center justify-between p-[10px_20px] font-regular">
              <div className="text-lg font-semibold">
                {translate(language, 'home.greetings').replace(
                  '{userName}',
                  userInfo ? userInfo.firstName : 'User',
                )}
              </div>
              <div className="-mt-[100px] -mr-[35px] text-xs">
                <img src="/assets/images/greeting.png" alt="greeting" />
              </div>
            </div>

            {/* Points Container */}
            <div
              className="mb-[10px] flex flex-col items-center justify-center rounded-[20px] bg-[#9eede8] p-[10px_20px] text-xs font-regular shadow-md cursor-pointer"
              onClick={handleNavigateToPoints}>
              <div className="text-xs font-regular flex items-center">
                {translate(language, 'home.pointsLabel')}
                <PointsRightArrowIcon />
              </div>
              <div className="flex items-center text-lg font-semibold">
                <ReactSVG
                  src="/assets/icons/coins.svg"
                  className="h-[25px] w-[25px] mr-[5px]"
                />
                {userInfo.points}
              </div>
            </div>
          </div>
        </section>

        {/* Sliders */}
        <HomeSlider
          title={translate(language, 'home.whatsNew')}
          items={whatsNewItems}
          loading={loading}
          imageLoading={imageLoading}
          renderLikeIcon={renderLikeIcon}
          language={language}
          navigate={navigate}
          settings={settings}
        />

        <HomeSlider
          title={translate(language, 'home.activities')}
          items={activityItems}
          loading={loading}
          imageLoading={imageLoading}
          renderLikeIcon={renderLikeIcon}
          language={language}
          navigate={navigate}
          settings={settings}
        />
      </div>

      <BottomNav />

      {/* Preloading tutorial images */}
      <div className="hidden">
        {tutorialImages.map(src => (
          <img key={src} src={src} alt="tutorial" />
        ))}
      </div>
    </>
  );
}

export default Home;
