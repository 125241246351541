import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const NotificationSkeleton = () => (
  <div className="mx-5 my-5">
    <div className="bg-white rounded-[20px] shadow-sm p-5 h-20 animate-pulse">
      {/* Title and Date Skeleton */}
      <div className="flex justify-between items-center mb-2.5">
        <div className="h-4 w-1/3 bg-gray-200 rounded" />
        <div className="h-4 w-1/4 bg-gray-200 rounded" />
      </div>
      {/* Description Skeleton */}
      <div className="h-4 w-3/4 bg-gray-200 rounded" />
    </div>
  </div>
);

const Notifications = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchNotifications = async () => {
      setLoading(true);

      try {
        const response = await axios.get(`/api/notification/notifications`);
        setNotifications(response.data.data);
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
        setError(translate(language, 'notifications.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [navigate, language]);

  const getNotificationTitle = notification => {
    if (language === 'en') return notification.title.en;
    if (language === 'zh') return notification.title.zh;
    if (language === 'zhhk') return notification.title.zhhk;
    return notification.title.en; // Default to English if language not found
  };

  const getNotificationBody = notification => {
    if (language === 'en') return notification.body.en;
    if (language === 'zh') return notification.body.zh;
    if (language === 'zhhk') return notification.body.zhhk;
    return notification.body.en; // Default to English if language not found
  };

  return (
    <>
      <TopNav text={translate(language, 'notifications.myNotifications')} />
      <div className="mt-[120px]">
        {loading ? (
          <>
            <NotificationSkeleton />
            <NotificationSkeleton />
            <NotificationSkeleton />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : notifications.length > 0 ? (
          notifications.map(notification => (
            <div
              key={notification._id}
              className="mx-5 my-5 p-5 bg-white rounded-[20px] shadow-sm">
              <div className="flex justify-between items-center w-full">
                <div className="text-xs font-bold">
                  {!notification.read && (
                    <span className="inline-block h-2 w-2 bg-red-500 rounded-full mr-2" />
                  )}
                  {getNotificationTitle(notification)}
                </div>
              </div>
              <div className="text-xs font-light mb-2.5">
                {new Date(notification.created).toLocaleString('en-US', {
                  month: 'short',
                  day: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </div>
              <div className="text-xs font-regular">
                {getNotificationBody(notification)}
              </div>
            </div>
          ))
        ) : (
          <div className="mx-5 my-5 p-5 bg-white rounded-[20px] shadow-sm">
            <p>{translate(language, 'notifications.noNotifications')}</p>
          </div>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default Notifications;
