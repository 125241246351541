import {useState, useContext, useRef, useEffect} from 'react';
import axios from 'axios';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrScanner from 'qr-scanner';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';

const InputWithIcon = ({value, onChange, placeholder, onScanClick}) => (
  <div className="relative w-full my-2.5">
    <input
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full h-10 px-4 pr-12 border border-gray-300 rounded-[20px] bg-white font-regular"
    />
    <div
      onClick={onScanClick}
      className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer text-gray-600 flex items-center justify-center">
      <QrCodeScannerIcon />
    </div>
  </div>
);

const Button = ({
  onClick,
  disabled,
  bgColor = 'bg-primary',
  children,
  loading,
}) => (
  <button
    onClick={onClick}
    disabled={disabled || loading}
    className={`w-full h-10 mt-[30px] px-0 border-none rounded-[20px] ${bgColor} text-white font-semibold cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed
    flex items-center justify-center`}>
    {loading ? <CircularProgress size={20} className="text-white" /> : children}
  </button>
);

const generateIdempotencyKey = () => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
};

const Transfer = () => {
  const {language} = useContext(LanguageContext);

  const [amount, setAmount] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [recipient, setRecipient] = useState('');
  const [isReviewing, setIsReviewing] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const videoElementRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [isTransferring, setIsTransferring] = useState(false);

  const scanQR = () => {
    setShowScanner(true);
  };

  const handleReview = () => {
    if (recipient && amount) {
      setIsReviewing(true);
    } else {
      setError(translate(language, 'points.fillAllFields'));
    }
  };

  const handleConfirmTransfer = async () => {
    setIsTransferring(true);
    setError('');

    const token = localStorage.getItem('token');
    setTransactionDetails(null);
    try {
      const response = await axios.post(
        '/api/transaction/transfer',
        {
          recipient,
          amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Idempotency-Key': generateIdempotencyKey(),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.status === 200) {
        setTransactionDetails(response.data);
      } else {
        setError(translate(language, 'points.transferFail'));
      }
    } catch (err) {
      console.error('Transfer Error:', err);
      setError(translate(language, 'points.transferFail'));
    } finally {
      setIsTransferring(false);
    }
  };

  const handleClose = () => {
    if (videoElementRef.current) {
      const tracks = videoElementRef.current.srcObject?.getTracks();
      if (tracks) {
        tracks.forEach(track => track.stop());
      }
    }
    setShowScanner(false);
  };

  useEffect(() => {
    let qrScanner;

    if (showScanner && videoElementRef.current) {
      qrScanner = new QrScanner(
        videoElementRef.current,
        result => {
          if (result) {
            setRecipient(result.data);
            setShowScanner(false);
          }
        },
        {
          highlightScanRegion: true,
          facingMode: 'environment',
        },
      );

      qrScanner.start().catch(error => {
        console.error('QR Scanner Error:', error);
        setErrorMessage(translate(language, 'qrScan.focusOnQrCode'));
      });
    }

    return () => {
      if (qrScanner) {
        qrScanner.stop();
      }
    };
  }, [showScanner]);

  return (
    <>
      <TopNav text={translate(language, 'points.transfer')} />

      {/* Loading Spinner */}
      {loading && (
        <div className="fixed inset-0 bg-white/80 flex justify-center items-center z-50">
          <CircularProgress size={60} />
        </div>
      )}

      <div className="flex flex-col items-center justify-center p-5 text-center font-regular">
        {error && <p className="text-red-500">{error}</p>}

        {!transactionDetails ? (
          <>
            {!isReviewing ? (
              <>
                <InputWithIcon
                  value={recipient}
                  onChange={e => setRecipient(e.target.value)}
                  placeholder={translate(language, 'points.recipient')}
                  onScanClick={scanQR}
                />
                <input
                  type="number"
                  placeholder={translate(language, 'points.amount')}
                  value={amount}
                  onChange={e => setAmount(e.target.value)}
                  className="w-full h-10 px-4 border border-gray-300 rounded-[20px] bg-white font-regular my-2.5"
                />
                <Button onClick={handleReview} disabled={!recipient || !amount}>
                  {translate(language, 'points.review')}
                </Button>
              </>
            ) : (
              <div className="mt-5 p-5 border border-gray-300 rounded-[10px] bg-gray-50">
                <h3>{translate(language, 'points.reviewTransfer')}</h3>
                <p>
                  {translate(language, 'points.recipient')}: {recipient}
                </p>
                <p>
                  {translate(language, 'points.amount')}: {amount}
                </p>
                <Button
                  onClick={handleConfirmTransfer}
                  disabled={isTransferring}
                  loading={isTransferring}>
                  {translate(language, 'points.confirm')}
                </Button>
                <Button
                  onClick={() => setIsReviewing(false)}
                  bgColor="bg-gray-400"
                  disabled={isTransferring}>
                  {translate(language, 'points.edit')}
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <CheckCircleIcon className="text-[60px] text-[#3BB143]" />
            <div className="text-xl text-green-600 mt-5">
              {translate(language, 'points.transactionSuccess')}
            </div>
            <div className="text-base text-gray-700">
              <p>{translate(language, 'points.recipient')}:</p>
              {transactionDetails.recipient.email && (
                <p>{transactionDetails.recipient.email}</p>
              )}
              {transactionDetails.recipient.phone && (
                <p>{transactionDetails.recipient.phone}</p>
              )}
              {transactionDetails.recipient.shortId && (
                <p>{transactionDetails.recipient.shortId}</p>
              )}
              <p>
                {translate(language, 'points.amount')}:{' '}
                {transactionDetails.amount}
              </p>
              <p>
                {translate(language, 'points.transactionId')}:{' '}
                {transactionDetails.transactionId}
              </p>
              <br />
              <p>{translate(language, 'points.transferProcessed')}</p>
            </div>
          </>
        )}
      </div>

      {/* QR Scanner Modal */}
      {showScanner && (
        <div className="fixed inset-0 bg-black z-50 flex flex-col items-center">
          <div className="relative w-full h-screen flex justify-center">
            <div className="w-full h-full flex justify-center items-center overflow-hidden">
              <video
                ref={videoElementRef}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="absolute bottom-[100px] left-0 w-full flex flex-col justify-end items-center z-10 pointer-events-none">
              <div className="text-white mt-5 text-base text-center">
                {translate(language, 'qrScan.aimAtQrCode')}
              </div>
              <div className="text-white mt-5 text-base text-center">
                {translate(language, 'qrScan.fitQrCodeInBox')}
              </div>
              <div className="text-white mt-5 text-base text-center">
                {translate(language, 'qrScan.additionalMessage')}
              </div>
              {errorMessage && (
                <div className="text-red-500 text-base mt-2.5">
                  {errorMessage}
                </div>
              )}
            </div>
            <CloseIcon
              onClick={handleClose}
              className="absolute top-2.5 right-2.5 text-white text-[4rem] z-30 cursor-pointer"
            />
          </div>
        </div>
      )}

      <BottomNav />
    </>
  );
};

export default Transfer;
