import axios from 'axios';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

const ArticleCard = ({onClick, children}) => (
  <div
    onClick={onClick}
    className="w-full mb-5 cursor-pointer bg-white rounded-lg shadow-md p-5 text-left hover:shadow-lg transition-shadow duration-200">
    {children}
  </div>
);

const SkeletonCard = () => (
  <ArticleCard>
    {/* Image Skeleton */}
    <div className="w-full h-[150px] bg-gray-200 animate-pulse rounded-lg" />
    {/* Title Skeleton */}
    <div className="h-5 w-4/5 bg-gray-200 animate-pulse rounded mt-2.5" />
  </ArticleCard>
);

const MyLikes = ({language}) => {
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikedArticles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/liked`,
        );
        setLikedArticles(response.data);
      } catch (err) {
        console.error('Error fetching liked articles:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLikedArticles();
  }, [navigate]);

  const handleCardClick = articleId => {
    navigate(`/article/${articleId}`, {state: {showBackIcon: true}});
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center p-5 min-w-[80%]">
          {[1, 2, 3].map((_, index) => (
            <SkeletonCard key={index} />
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center p-5">
          {likedArticles.map(article => (
            <ArticleCard
              key={article._id}
              onClick={() => handleCardClick(article._id)}>
              <img
                src={article.coverImg[language]}
                alt={article.title[language]}
                className="w-full h-[150px] object-cover rounded-lg"
              />
              <h3 className="text-lg text-gray-800 mt-2.5 font-medium">
                {article.title[language]}
              </h3>
            </ArticleCard>
          ))}
        </div>
      )}
    </>
  );
};

export default MyLikes;
