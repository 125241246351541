import InfoIcon from '@mui/icons-material/Info';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const BackIconComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const showBackIcon = location.state?.showBackIcon;

  if (!showBackIcon) return null;

  return (
    <div
      onClick={() => navigate(-1)}
      className="absolute left-5 flex items-center justify-center w-10 h-10 rounded-full bg-white cursor-pointer">
      <svg className="w-5 h-5 text-[#030303]" viewBox="0 0 256 512">
        <path d="M192 448c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l137.4 137.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448z" />
      </svg>
    </div>
  );
};

const InfoButton = ({info, onClick}) => {
  if (!info) return null;

  return (
    <div
      onClick={onClick}
      className="absolute right-5 flex items-center justify-center cursor-pointer">
      <InfoIcon className="text-white w-[30px] h-[30px]" />
    </div>
  );
};

const TopNav = ({text = 'Title', info}) => {
  const {language} = useContext(LanguageContext);
  const [, setIsMobile] = useState(window.innerWidth / window.innerHeight < 1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInfoClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Placeholder for fixed positioning - Increased height */}
      <div className="h-[80px]" />

      {/* Top Navigation Bar - Adjusted padding and height */}
      <div className="fixed top-0 left-0 right-0 flex items-center justify-between bg-primary shadow-nav h-[80px] min-w-[300px] w-full z-10 max-w-[680px] mx-auto px-5">
        <BackIconComponent />
        <div className="font-semibold text-white text-xl text-center flex-grow py-5">
          {text}
        </div>
        <InfoButton info={info} onClick={handleInfoClick} />
      </div>

      {/* Info Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate(language, 'infoDialog.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{info}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TopNav;
