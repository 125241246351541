// components/SkeletonLoader.jsx
const SkeletonLoader = () => {
  return (
    <div className="p-5">
      <div className="h-5 w-full bg-gray-200 rounded animate-shimmer mb-2.5" />
      <div className="h-5 w-full bg-gray-200 rounded animate-shimmer mb-2.5" />
      <div className="h-5 w-3/4 bg-gray-200 rounded animate-shimmer mb-2.5" />
    </div>
  );
};

export default SkeletonLoader;
