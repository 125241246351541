import {useContext, useState, useEffect} from 'react';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 120px);
  font-family: Arial, sans-serif;
  padding: 20px;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const TextContent = styled.div`
  white-space: pre-wrap;
  padding: 0 20px;

  h1,
  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 600;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    margin-bottom: 16px;
  }

  ul,
  ol {
    margin-bottom: 16px;
    padding-left: 24px;
  }

  li {
    margin-bottom: 8px;
  }
`;

const TermsPrivacy = () => {
  const {language} = useContext(LanguageContext);
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await fetch(
          'https://carbonpoint.io/documents/privacyPolicy.txt',
        );
        const text = await response.text();

        // Convert text to HTML with proper formatting
        const formattedContent = text
          // Convert section headers
          .replace(/^(\d+\.\s+.+)$/gm, '<h2>$1</h2>')
          // Convert paragraphs
          .split('\n\n')
          .map(paragraph => {
            if (paragraph.trim().startsWith('•')) {
              // Convert bullet points to list items
              const items = paragraph
                .split('\n')
                .map(item => item.trim().replace(/^•\s*/, ''))
                .filter(Boolean)
                .map(item => `<li>${item}</li>`)
                .join('');
              return `<ul>${items}</ul>`;
            }
            return `<p>${paragraph.trim()}</p>`;
          })
          .join('');

        setContent(formattedContent);
      } catch (error) {
        console.error('Error fetching privacy policy:', error);
        setContent('Error loading privacy policy. Please try again later.');
      }
    };

    fetchPrivacyPolicy();
  }, []);

  return (
    <>
      <TopNav text={translate(language, 'termsPrivacy.title')} />
      <Container>
        <TextContent dangerouslySetInnerHTML={{__html: content}} />
      </Container>
      <BottomNav />
    </>
  );
};

export default TermsPrivacy;
