import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {useContext} from 'react';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import {validatePassword} from '../utils/validation';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: ${props => props.fontFamily};
  height: 80vh;
`;

const Text = styled.div`
  font-family: ${props => props.fontFamily};
  margin: 20px 0;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #ffffff;
  font-family: ${props => props.fontFamily};
`;

const ResetButton = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 20px;
  padding: 0px 16px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
`;

const ResetPassword = () => {
  const {language} = useContext(LanguageContext);
  const navigate = useNavigate();
  const {token} = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async () => {
    setError('');
    setLoading(true);

    if (!validatePassword(password)) {
      setError(translate(language, 'resetPassword.weakPassword'));
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError(translate(language, 'resetPassword.passwordMismatch'));
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/resetPassword`,
        {token, password},
        {withCredentials: true},
      );

      if (response.status === 200) {
        navigate('/message', {
          state: {
            message: translate(language, 'resetPassword.success'),
            buttonText: translate(language, 'resetPassword.returnToLogin'),
            buttonLink: '/login',
          },
        });
      } else {
        setError(translate(language, 'resetPassword.failed'));
      }
    } catch (error) {
      console.error('Reset password error:', error);
      setError(
        error.response?.data?.message ||
          translate(language, 'resetPassword.failed'),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Text>{translate(language, 'resetPassword.header')}</Text>
      {error && <ErrorText>{error}</ErrorText>}
      <UserInput
        type="password"
        placeholder={translate(language, 'resetPassword.newPassword')}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <UserInput
        type="password"
        placeholder={translate(language, 'resetPassword.confirmPassword')}
        value={confirmPassword}
        onChange={e => setConfirmPassword(e.target.value)}
      />
      <ResetButton onClick={handleResetPassword} disabled={loading}>
        {translate(language, 'resetPassword.reset')}
      </ResetButton>
    </Container>
  );
};

export default ResetPassword;
