import {ThemeProvider, createTheme} from '@mui/material/styles';
import {createContext, useReducer, useState, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import LanguageProvider from './contexts/LanguageProvider';
import Article from './screens/Article';
import DynamicMessagePage from './screens/DynamicMessagePage';
import Explorer from './screens/Explorer';
import ForgotPassword from './screens/ForgotPassword';
import Home from './screens/Home';
import Login from './screens/Login';
import MyRewards from './screens/MyRewards';
import Partner from './screens/Partner';
import Points from './screens/Points';
import Transfer from './screens/Transfer';
import Notifications from './screens/Notifications';
import QRScanPage from './screens/QRScanPage';
import Redeem from './screens/Redeem';
import Saved from './screens/Saved';
import UserEdit from './screens/UserEdit';
import Settings from './screens/Settings';
import Signup from './screens/Signup';
import TermsPrivacy from './screens/TermsPrivacy';
import UserGuide from './screens/UserGuide';
import UserProfile from './screens/UserProfile';
import ResetPassword from './screens/ResetPassword';
import axios from 'axios';
import {initGA, logPageView} from './utils/analytics';

export const UserContext = createContext();
export const LanguageContext = createContext();

axios.defaults.baseURL = `${process.env.REACT_APP_API_URI}`;
axios.defaults.withCredentials = true;

const initialState = {
  // your initial state here
};

const reducer = {
  // your reducer logic here
};

const basicTheme = createTheme(); // A basic theme for testing

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [language, setLanguage] = useState(
    localStorage.getItem('Language') || 'en',
  );
  //const navigate = useNavigate();
  // Axios Interceptor for 401 Unauthorized
  //useEffect(() => {
  //  const responseInterceptor = axios.interceptors.response.use(
  //    response => response,
  //    error => {
  //      if (error.response && error.response.status === 401) {
  //        navigate('/login'); // Redirect to login if 401 Unauthorized
  //      }
  //      return Promise.reject(error);
  //    },
  //  );
  //
  //  return () => {
  //    // Eject interceptor when component unmounts
  //    axios.interceptors.response.eject(responseInterceptor);
  //  };
  //}, [navigate]);
  //
  //const checkAuth = async () => {
  //  const authenticated = await isAuthenticated();
  //  if (!authenticated) {
  //    navigate('/login'); // Redirect to login if not authenticated
  //  }
  //};

  // Handle Visibility Change
  //useEffect(() => {
  //  const handleVisibilityChange = () => {
  //    if (document.visibilityState === 'visible') {
  //      checkAuth(); // Check auth when the tab becomes visible
  //    }
  //  };
  //
  //  document.addEventListener('visibilitychange', handleVisibilityChange);
  //
  //  // Perform initial auth check
  //  //checkAuth();
  //
  //  return () => {
  //    document.removeEventListener('visibilitychange', handleVisibilityChange);
  //  };
  //}, [navigate]);

  const switchLanguage = lang => {
    setLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  useEffect(() => {
    // Replace 'G-XXXXXXXXXX' with your actual Google Analytics measurement ID
    initGA('G-457867360');
    logPageView();
  }, []);

  return (
    <LanguageProvider value={{language, switchLanguage}}>
      <ThemeProvider theme={basicTheme}>
        <UserContext.Provider value={{state, dispatch}}>
          <LanguageContext.Provider value={{language, switchLanguage}}>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/explorer"
                element={
                  <ProtectedRoute>
                    <Explorer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/redeem"
                element={
                  <ProtectedRoute>
                    <Redeem />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/myRewards"
                element={
                  <ProtectedRoute>
                    <MyRewards />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/saved"
                element={
                  <ProtectedRoute>
                    <Saved />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-edit"
                element={
                  <ProtectedRoute>
                    <UserEdit />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-guide"
                element={
                  <ProtectedRoute>
                    <UserGuide />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/terms-privacy"
                element={
                  <ProtectedRoute>
                    <TermsPrivacy />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/points"
                element={
                  <ProtectedRoute>
                    <Points />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/transfer"
                element={
                  <ProtectedRoute>
                    <Transfer />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/article/:id"
                element={
                  <ProtectedRoute>
                    <Article />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/partner/:id"
                element={
                  <ProtectedRoute>
                    <Partner />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/scanQR"
                element={
                  <ProtectedRoute>
                    <QRScanPage />
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/message" element={<DynamicMessagePage />} />
              <Route path="/resetPassword/:token" element={<ResetPassword />} />
              {/* other routes */}
            </Routes>
          </LanguageContext.Provider>
        </UserContext.Provider>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
