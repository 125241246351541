import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  font-family: ${props => props.fontFamily};
  text-align: center;
  color: #333;
  padding: 20px;
  background-color: #f1f6f8;
  max-width: 100%;
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
`;

const Name = styled.h1`
  font-size: 24px;
  color: #333;
  font-weight: bold;
  margin: 20px 0;
  font-family: ${props => props.fontFamily};
`;

const Description = styled.p`
  font-size: 16px;
  color: #666;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
`;

const Button = styled.button`
  background-color: #3ddbd1;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 20px 0;
  cursor: pointer;
  font-family: ${props => props.fontFamily};
`;

const PointsInfo = styled.div`
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px 0;
  font-family: ${props => props.fontFamily};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const PartnerPage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState('');

  useEffect(() => {
    const fetchPartnerDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/detail/${id}`,
        );
        setPartner(response.data);
      } catch (err) {
        console.error('Error fetching partner details:', err);
        setError(translate(language, 'partner.error') + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetail();
  }, [id, navigate, language]);

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'partner.detail')} />
        <div className="text-center text-gray-800 p-5 bg-[#f1f6f8] max-w-full font-regular">
          <button
            onClick={() => navigate('/')}
            className="absolute top-2.5 right-2.5 bg-transparent border-none text-2xl cursor-pointer">
            &times;
          </button>

          {/* Image Skeleton */}
          <div className="w-full h-[200px] bg-gray-200 animate-pulse rounded-lg" />

          {/* Name Skeleton */}
          <div className="my-5">
            <div className="h-8 w-[200px] bg-gray-200 animate-pulse rounded mx-auto" />
          </div>

          {/* Description Skeleton */}
          <div className="space-y-2 my-5">
            <div className="h-4 w-full bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[90%] bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[95%] bg-gray-200 animate-pulse rounded" />
          </div>

          {/* Button Skeleton */}
          <div className="h-10 w-[150px] bg-gray-200 animate-pulse rounded mx-auto my-5" />

          {/* Points Info Skeletons */}
          <div className="bg-gray-100 p-5 rounded-[20px] my-2.5 space-y-2">
            <div className="h-4 w-[100px] bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[150px] bg-gray-200 animate-pulse rounded" />
          </div>

          <div className="bg-gray-100 p-5 rounded-[20px] my-2.5 space-y-2">
            <div className="h-4 w-[100px] bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[150px] bg-gray-200 animate-pulse rounded" />
          </div>
        </div>
        <BottomNav />
      </div>
    );
  }

  return (
    <div>
      <TopNav text={translate(language, 'partner.detail')} />
      {partner && (
        <Container fontFamily={fontFamilies.regular}>
          <CloseButton onClick={() => navigate('/')}>&times;</CloseButton>
          <HeaderImage src={partner.nameImg} alt="Cover" />
          <Name fontFamily={fontFamilies.bold}>{partner.name[language]}</Name>
          <Description fontFamily={fontFamilies.semiBold}>
            {partner.description[language]}
          </Description>
          {partner.webLink && (
            <Button
              fontFamily={fontFamilies.regular}
              onClick={() => window.open(partner.webLink, '_blank')}
              disabled={!partner.webLink}
              className={`${!partner.webLink ? 'opacity-50 cursor-not-allowed' : ''}`}>
              {translate(language, 'partner.shopNow')}
            </Button>
          )}
          {/* <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>{translate(language, 'partner.basePoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio,
              )}
            </p>
          </PointsInfo>
          <PointsInfo fontFamily={fontFamilies.semiBoldItalic}>
            <p>{translate(language, 'partner.extraPoints')}</p>
            <p>
              {translate(language, 'partner.pointsInfo').replace(
                '{points}',
                partner.pointsRatio * 3,
              )}
            </p>
          </PointsInfo> */}
        </Container>
      )}
      <BottomNav />
    </div>
  );
};

export default PartnerPage;
