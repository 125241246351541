// components/ProtectedRoute.jsx
import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {isAuthenticated} from '../utils/auth';
import SkeletonLoader from './SkeletonLoader'; // Import the SkeletonLoader component

const ProtectedRoute = ({children}) => {
  const [authStatus, setAuthStatus] = useState('checking'); // 'checking', 'authenticated', 'not-authenticated'
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('Current path:', location.pathname); // Debugging statement

    const verifyAuth = async () => {
      const isValid = await isAuthenticated();
      if (isValid) {
        setAuthStatus('authenticated');
      } else {
        setAuthStatus('not-authenticated');
        navigate('/login');
      }
    };

    // Skip authentication check for specific routes
    if (location.pathname !== '/signup') {
      verifyAuth();
    } else {
      setAuthStatus('authenticated');
    }
  }, [location.pathname, navigate]);

  if (authStatus === 'checking') {
    return <SkeletonLoader />; // Display the skeleton loader when checking authentication
  }

  return authStatus === 'authenticated' ? children : null;
};

export default ProtectedRoute;
