import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import QRCode from 'qrcode.react';
import Barcode from 'react-barcode';

const RewardSkeleton = () => (
  <div className="bg-gray-200 animate-pulse rounded-lg h-[120px] mb-2.5" />
);

const Rewards = ({type}) => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [barCode, setBarCode] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [intervalId, setIntervalId] = useState(null);

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const fetchRewards = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/redeem/myredeems?type=${type}`,
      );

      const redeemItemResponse = await axios.get(
        `${process.env.REACT_APP_API_URI}/api/redeem/list`,
      );

      const myRewards = response.data.map(reward => {
        const redeemItem = redeemItemResponse.data.find(
          item => item._id === reward.redeemItemSnapshot._id,
        );
        const replaceItem = redeemItem || reward.redeemItemSnapshot;

        return {
          ...reward,
          redeemItemSnapshot: replaceItem,
          _id: reward._id,
        };
      });

      const uniqueRewards = Array.from(new Set(myRewards.map(r => r._id))).map(
        id => myRewards.find(r => r._id === id),
      );

      const sortedRewards = uniqueRewards.sort(
        (a, b) => new Date(b.created) - new Date(a.created),
      );

      setRewards(sortedRewards);
    } catch (error) {
      console.error('Failed to fetch rewards:', error);
      setError(translate(language, 'rewards.error'));
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = reward => {
    if (reward.used) {
      return;
    }
    setSelectedReward(reward);
    setShowConfirmDialog(true);
  };

  const handleConfirmOpen = async () => {
    setShowConfirmDialog(false);

    if (selectedReward) {
      // Call API to mark as pending/used
      try {
        axios
          .get(
            `${process.env.REACT_APP_API_URI}/api/redeem/use/${selectedReward._id}`,
          )
          .catch(error => {
            console.error('Error marking reward as used:', error);
          });
      } catch (error) {
        console.error('Error marking reward as used:', error);
      }

      // Show the actual coupon
      if (selectedReward.itemContent?.qrcode) {
        setQrCode(selectedReward.itemContent.qrcode);
        setBarCode(null);
      } else if (selectedReward.itemContent?.barCode) {
        setBarCode(selectedReward.itemContent.barCode);
        setQrCode(null);
      }
      setDialogOpen(true);
      setTimeLeft(300); // Reset timer to 5 minutes

      // Start countdown
      const id = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(id);
            handleCloseDialog();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
      setIntervalId(id);
    }
  };

  const handleCloseDialog = async () => {
    setDialogOpen(false);
    setQrCode(null);
    setBarCode(null);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    setTimeLeft(300);

    // Refresh the rewards list
    await fetchRewards();
  };

  // Initial fetch
  useEffect(() => {
    fetchRewards();
  }, [language, type]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  return (
    <>
      <TopNav text={translate(language, 'rewards.myRewards')} />
      <div className="font-regular text-center text-gray-800 p-5 max-w-[400px] mx-auto bg-[#f1f6f8]">
        {loading ? (
          <div className="grid grid-cols-2 gap-2.5 mt-5">
            {[...Array(4)].map((_, index) => (
              <RewardSkeleton key={index} />
            ))}
          </div>
        ) : error ? (
          <p>
            {translate(language, 'rewards.error')} {error}
          </p>
        ) : rewards && rewards.length > 0 ? (
          <div className="grid grid-cols-2 gap-2.5 mt-5">
            {rewards.map(reward => (
              <div
                key={reward._id}
                onClick={() => handleCardClick(reward)}
                className={`bg-white rounded-lg p-4 flex flex-col gap-2 shadow-sm transition-shadow
                  ${
                    !reward.used
                      ? 'cursor-pointer hover:shadow-md'
                      : 'cursor-not-allowed opacity-75'
                  }`}>
                {reward.redeemItemSnapshot.image && (
                  <img
                    src={reward.redeemItemSnapshot.image}
                    alt={reward.redeemItemSnapshot.name[language]}
                    className="w-full h-[77px] object-contain bg-gray-50 rounded-md"
                  />
                )}
                <span className="text-sm font-medium text-gray-900">
                  {reward.redeemItemSnapshot.name[language]}
                </span>
                <div className="flex flex-col gap-1 text-xs text-gray-600">
                  <span>
                    {reward.quantity} {translate(language, 'rewards.quantity')}
                  </span>
                  <span>
                    {new Date(reward.created).toLocaleString('en-US', {
                      timeZone: 'Asia/Hong_Kong',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: '2-digit',
                    })}
                  </span>
                  <div className="flex items-center gap-1">
                    <span>{translate(language, 'rewards.status')}:</span>
                    <span
                      className={`
                      ${!reward.used ? 'text-green-600' : 'text-gray-500'}
                    `}>
                      {!reward.used
                        ? translate(language, 'rewards.notUsed')
                        : translate(language, 'rewards.used')}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="font-regular text-center">
            <p>{translate(language, 'rewards.noRewardsFound')}</p>
            <button
              onClick={() =>
                navigate('/explorer', {state: {showBackIcon: false}})
              }
              className="bg-primary text-white px-5 py-2.5 border-none rounded-lg cursor-pointer mt-5 font-semibold">
              {translate(language, 'rewards.viewCoupons')}
            </button>
          </div>
        )}
      </div>

      {/* Confirmation Dialog */}
      {showConfirmDialog && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={() => setShowConfirmDialog(false)}
          />

          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                <div className="text-center">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                    {translate(language, 'rewards.confirmOpen')}
                  </h3>
                  <p className="text-sm text-gray-500 mb-4">
                    {translate(language, 'rewards.confirmOpenMessage')}
                  </p>
                  <p className="text-sm text-red-500 font-medium mb-4">
                    {translate(language, 'rewards.validityWarning')}
                  </p>
                </div>
                {/* Add TOC section */}
                {selectedReward?.redeemItemSnapshot?.toc?.[language] && (
                  <div className="mt-4 text-sm text-gray-600">
                    <p className="whitespace-pre-wrap">
                      {selectedReward.redeemItemSnapshot.toc[language]}
                    </p>
                  </div>
                )}
              </div>

              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={handleConfirmOpen}
                  className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm">
                  {translate(language, 'rewards.confirm')}
                </button>
                <button
                  type="button"
                  onClick={() => setShowConfirmDialog(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  {translate(language, 'rewards.cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Existing Coupon Dialog */}
      {dialogOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
            onClick={handleCloseDialog}
          />

          <div className="flex min-h-full items-center justify-center p-4">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                <div className="text-center">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                    {(qrCode || barCode) &&
                      rewards.find(
                        reward =>
                          reward.itemContent?.qrcode === qrCode ||
                          reward.itemContent?.barCode === barCode,
                      )?.redeemItemSnapshot.name[language]}
                  </h3>
                  {/* Countdown Timer */}
                  <div className="text-xl font-bold text-red-500 mb-4">
                    {formatTime(timeLeft)}
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center p-4">
                {qrCode && (
                  <QRCode
                    value={qrCode}
                    size={256}
                    className="block mx-auto my-5"
                  />
                )}
                {barCode && (
                  <Barcode
                    value={barCode}
                    width={2}
                    height={100}
                    format="CODE128"
                    className="my-5"
                  />
                )}

                <button
                  onClick={handleCloseDialog}
                  className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary/80 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:text-sm">
                  {translate(language, 'partner.close')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <BottomNav />
    </>
  );
};

export default Rewards;
