import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useState} from 'react';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const Settings = () => {
  const {language, switchLanguage} = useContext(LanguageContext);
  const [userInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [biometricEnabled, setBiometricEnabled] = useState(
    Cookies.get('userInfo')
      ? JSON.parse(Cookies.get('userInfo')).biometric
      : {},
  );
  const [isLoading] = useState(false);

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const handleEnableBiometricLogin = async () => {
    // Check if the app is running inside a WebView
    const isNative = navigator.userAgent.includes('wv');

    if (isNative) {
      // If it's a WebView, trigger native biometric login via postMessage
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'enableBiometricLogin',
          userInfo: {
            name: userInfo.firstName + ' ' + userInfo.lastName,
            displayName: userInfo.firstName,
            email: userInfo.email,
          },
        }),
      );
      return;
    }

    if (!window.PublicKeyCredential) {
      alert('Biometric login is not supported by this browser.');
      return;
    }

    try {
      // Step 1: Generate a challenge on the frontend (random byte array)
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      // Step 2: Create PublicKeyCredentialCreationOptions object
      const publicKey = {
        challenge: challenge,
        rp: {
          name: 'Carbon Point',
        },
        user: {
          id: new Uint8Array(
            [...Array(16)].map(() => Math.floor(Math.random() * 256)),
          ),
          name: userInfo.firstName + ' ' + userInfo.lastName,
          displayName: userInfo.firstName,
          email: userInfo.email,
        },
        pubKeyCredParams: [{type: 'public-key', alg: -7}], // ES256
        timeout: 60000,
        authenticatorSelection: {
          authenticatorAttachment: 'platform',
          userVerification: 'required',
        },
        attestation: 'direct',
      };

      const credential = await navigator.credentials.create({publicKey});
      const credentialResponse = {
        userId: credential.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(credential.rawId))),
        type: credential.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.clientDataJSON),
            ),
          ),
          attestationObject: btoa(
            String.fromCharCode(
              ...new Uint8Array(credential.response.attestationObject),
            ),
          ),
        },
      };

      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/registerBiometric`,
        {
          credential: {
            ...credentialResponse,
            publicKey: credentialResponse.response.attestationObject,
            challenge: btoa(String.fromCharCode(...challenge)),
          },
        },
      );

      //will use this store when login
      const rawIdBase64 = btoa(
        String.fromCharCode(...new Uint8Array(credential.rawId)),
      );
      localStorage.setItem('credentialId', rawIdBase64);
      setBiometricEnabled(true);
      alert('Biometric login enabled successfully.');
    } catch (err) {
      console.error('Failed to create a credential:', err);
      alert('Error enabling biometric: Not Supported Device');
    }
  };

  return (
    <div className="min-h-screen pb-[100px]">
      {isLoading && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      )}

      <TopNav text={translate(language, 'settings.language')} />

      <div className="mx-7 mt-7 font-regular">
        {/* Language Section */}
        <div className="flex justify-between items-center">
          <div>{translate(language, 'settings.language')}</div>
          <div className="flex items-center space-x-2">
            {['en', 'zh', 'zhhk'].map(lang => (
              <button
                key={lang}
                onClick={() => handleLanguageSwitch(lang)}
                className={`px-2.5 py-1.5 rounded-md transition-colors
                  ${
                    language === lang
                      ? 'bg-primary text-white'
                      : 'bg-gray-100 text-primary'
                  }`}>
                {lang === 'en' ? 'EN' : lang === 'zh' ? '简' : '繁'}
              </button>
            ))}
          </div>
        </div>

        {/* Biometric Login Section */}
        <div
          onClick={handleEnableBiometricLogin}
          className="mt-7 flex items-center cursor-pointer">
          <span>{translate(language, 'settings.enableBiometricLogin')}</span>
          {biometricEnabled && (
            <span className="ml-2.5 text-green-500 text-xl">✓</span>
          )}
        </div>
      </div>

      <BottomNav />
    </div>
  );
};

export default Settings;
