import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import RedeemItemDetail from './RedeemItemDetail';

const RedeemPage = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [vouchers, setVouchers] = useState([]);
  const [, setLoading] = useState(false);
  const [, setError] = useState(null);

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(Cookies.get('userInfo')));
    } catch {
      null;
    }
    const fetchUserInfo = async () => {
      try {
        await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
        );
        setUserInfo(JSON.parse(Cookies.get('userInfo')));
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  useEffect(() => {
    const fetchVouchers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/redeem/list`);
        const sortedVouchers = response.data.sort((a, b) =>
          b._id.localeCompare(a._id),
        );
        setVouchers(sortedVouchers);
      } catch (err) {
        console.error('Error fetching vouchers:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchVouchers();
  }, [navigate]);

  const handleOpen = voucher => {
    setSelectedVoucher(voucher);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const exchangeVouchers = vouchers.filter(
    voucher => voucher.type === 'exchange',
  );
  const otherVouchers = vouchers.filter(voucher => voucher.type === 'coupon');

  return (
    <>
      <TopNav
        text={translate(language, 'redeemPage.title')}
        info={translate(language, 'infoDialog.redeemDescription')}
      />
      <div
        className={`text-center text-gray-800 p-5 max-w-md mx-auto bg-[#f1f6f8]`}
        style={{fontFamily: fontFamilies.regular}}>
        <div
          className="flex justify-between p-3 bg-white my-2.5 rounded-lg"
          style={{fontFamily: fontFamilies.medium}}>
          <span>{translate(language, 'redeemPage.pointsAvailable')}</span>
          <span>{userInfo.points}</span>
        </div>

        <div className="grid grid-cols-2 gap-2.5 my-5">
          {exchangeVouchers.map((voucher, index) => (
            <div
              key={index}
              onClick={() => handleOpen(voucher)}
              className="bg-white rounded-lg p-2.5 flex flex-col justify-center items-center text-center cursor-pointer">
              <img
                src={voucher.image}
                alt={voucher.name[language]}
                className="w-full h-[77px] object-cover object-center mb-2.5"
              />
              <span className="mb-1.5" style={{fontFamily: fontFamilies.bold}}>
                {voucher.name[language]}
              </span>
              <span style={{fontFamily: fontFamilies.light}}>
                {voucher.points} pts
              </span>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-2 gap-2.5 my-5">
          {otherVouchers.map((voucher, index) => (
            <div
              key={index}
              onClick={() => handleOpen(voucher)}
              className="bg-white rounded-lg p-2.5 flex flex-col justify-center items-center text-center cursor-pointer">
              <img
                src={voucher.image}
                alt={voucher.name[language]}
                className="w-full h-[77px] object-cover object-center mb-2.5"
              />
              <span className="mb-1.5" style={{fontFamily: fontFamilies.bold}}>
                {voucher.name[language]}
              </span>
              <span style={{fontFamily: fontFamilies.light}}>
                {voucher.points} pts
              </span>
            </div>
          ))}
        </div>

        <div
          className="bg-white p-5 rounded-lg"
          style={{fontFamily: fontFamilies.medium}}>
          <p>{translate(language, 'redeemPage.redeemNow')}</p>
          <p>{translate(language, 'redeemPage.redeemInstructions')}</p>
        </div>
      </div>
      <RedeemItemDetail
        isOpen={isOpen}
        selectedVoucher={selectedVoucher}
        handleClose={handleClose}
      />
      <BottomNav />
    </>
  );
};

export default RedeemPage;
