import React, {useState, useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const getTutorialImages = () => [
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial1.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial2.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial3.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial4.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial5.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial6.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial7.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial8.png`,
];

const TutorialOverlay = ({onComplete}) => {
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState(true);

  const tutorialImages = getTutorialImages();

  const handleNextTutorial = () => {
    if (tutorialIndex < tutorialImages.length - 1) {
      setTutorialIndex(tutorialIndex + 1);
      setImageLoading(true);
    } else {
      onComplete();
    }
  };

  const handleImageLoad = () => {
    setImageLoading(false);
  };

  useEffect(() => {
    // Preload images
    tutorialImages.forEach(src => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const styles = {
    tutorialOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      zIndex: 1001,
      cursor: 'pointer',
    },
    tutorialImage: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: 'auto',
    },
    spinnerOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 1002,
    },
    hiddenImage: {
      display: 'none',
    },
  };

  return (
    <>
      <div style={styles.tutorialOverlay} onClick={handleNextTutorial}>
        {imageLoading && (
          <div style={styles.spinnerOverlay}>
            <CircularProgress />
          </div>
        )}
        <img
          src={tutorialImages[tutorialIndex]}
          alt={`Tutorial step ${tutorialIndex + 1}`}
          style={styles.tutorialImage}
          onLoad={handleImageLoad}
        />
      </div>
      {/* Preloading tutorial images */}
      {tutorialImages.map(src => (
        <img key={src} src={src} style={styles.hiddenImage} alt="tutorial" />
      ))}
    </>
  );
};

export default TutorialOverlay;
