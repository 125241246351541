import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import {useContext, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';
import Cookies from 'js-cookie';
import {getTutorialImages} from '../components/TutorialOverlay';

const LanguageButton = ({active, onClick, children}) => (
  <button
    className={`bg-transparent border-none cursor-pointer font-regular 
      ${active ? 'font-bold text-black' : 'font-normal text-gray-500'}`}
    onClick={onClick}>
    {children}
  </button>
);

const LoginButton = ({onClick, text}) => (
  <button
    className="w-full h-10 mt-5 px-4 rounded-[20px] bg-primary text-white font-semibold cursor-pointer"
    onClick={onClick}>
    {text}
  </button>
);

const Login = props => {
  const navigate = useNavigate();
  const {language, switchLanguage} = useContext(LanguageContext);

  const [userIdentifier, setUserIdentifier] = useState(
    localStorage.getItem('rememberUserIdentifier') || '',
  );
  const [password, setPassword] = useState(
    localStorage.getItem('rememberPassword') || '',
  );
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe') === 'true',
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const rememberMe = Cookies.get('rememberMe') === 'true';
    setRememberMe(rememberMe);
    if (rememberMe) {
      setUserIdentifier(Cookies.get('rememberUserIdentifier') || '');
    }
  }, []);

  const handleLanguageSwitch = lang => {
    switchLanguage(lang);
    localStorage.setItem('Language', lang);
  };

  const defaultProps = {
    text: translate(language, 'login.welcomeBack'),
    subText: translate(language, 'login.signInToAccess'),
    userInput: translate(language, 'login.email'),
    userPassword: translate(language, 'login.password'),
  };

  const validateUserIdentifier = identifier => {
    // Email regex
    const emailRe =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\\.,;:\s@"]+\.)+[^<>()[\\.,;:\s@"]{2,})$/i;
    // Phone number regex (simple example, adjust as needed)
    const phoneRe = /^\+?[1-9]\d{1,14}$/;
    return emailRe.test(identifier) || phoneRe.test(identifier);
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true);

    // Validate the userIdentifier format
    if (!validateUserIdentifier(userIdentifier)) {
      setError(translate(language, 'login.invalidUserIdentifier'));
      setLoading(false);
      return;
    }

    // Check if the userIdentifier is filled
    if (!userIdentifier) {
      setError(translate(language, 'login.userIdentifierRequired'));
      setLoading(false);
      return;
    }

    // Check if the password is filled
    if (!password) {
      setError(translate(language, 'login.passwordRequired'));
      setLoading(false);
      return;
    }

    try {
      // Make the login request to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/login`,
        {
          userIdentifier: userIdentifier,
          userPassword: password,
        },
        {withCredentials: true},
      );

      // Check if the login was successful (HTTP 200)
      if (response.status === 200) {
        // Handle "Remember Me" functionality
        if (rememberMe) {
          Cookies.set('rememberUserIdentifier', userIdentifier);
          Cookies.set('rememberMe', 'true');
        } else {
          Cookies.remove('rememberUserIdentifier');
          Cookies.remove('rememberMe');
        }

        // Navigate to the home page
        navigate('/');
      } else {
        setError('Login failed. Unexpected response.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(
        error.response?.data?.message || 'Failed to login. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBiometricLogin = async () => {
    const isNative = navigator.userAgent.includes('wv');

    if (isNative) {
      // If it's a WebView, trigger native biometric login via postMessage
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'biometricLogin',
        }),
      );
      return;
    }

    const credentialId = localStorage.getItem('credentialId');

    if (!credentialId || !window.PublicKeyCredential) {
      alert('Biometric login is not available or supported on this device.');
      return;
    }

    try {
      // Step 1: Generate a new challenge
      const challenge = new Uint8Array(32);
      window.crypto.getRandomValues(challenge);

      // Step 2: Create PublicKeyCredentialRequestOptions
      const publicKey = {
        challenge: challenge,
        allowCredentials: [
          {
            id: Uint8Array.from(atob(credentialId), c => c.charCodeAt(0)),
            type: 'public-key',
          },
        ],
        userVerification: 'required', // Requires biometric input
        timeout: 60000,
      };

      // Step 3: Use navigator.credentials.get to authenticate
      const assertion = await navigator.credentials.get({publicKey});

      // Step 4: Prepare the assertion for backend verification
      const assertionResponse = {
        id: assertion.id,
        rawId: btoa(String.fromCharCode(...new Uint8Array(assertion.rawId))),
        type: assertion.type,
        response: {
          clientDataJSON: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.clientDataJSON),
            ),
          ),
          authenticatorData: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.authenticatorData),
            ),
          ),
          signature: btoa(
            String.fromCharCode(
              ...new Uint8Array(assertion.response.signature),
            ),
          ),
          userHandle: assertion.response.userHandle
            ? btoa(
                String.fromCharCode(
                  ...new Uint8Array(assertion.response.userHandle),
                ),
              )
            : null,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URI}/api/auth/biometric_verify`,
        {
          credential: {
            ...assertionResponse,
            userId: assertion.id,
            signature: assertionResponse.response.signature, //missing
            challenge: btoa(String.fromCharCode(...challenge)),
          },
        },
      );
      if (response.status === 200) {
        navigate('/');
      } else {
        setError(translate(language, 'login.serverAuthenticationFailed'));
      }
    } catch (error) {
      console.error('Biometric login error:', error);
      alert(error);
      setError(
        translate(language, 'login.biometricFailed') +
          (error.response?.data?.message ||
            translate(language, 'login.tryAgain')),
      );
    } finally {
      setLoading(false);
    }
  };

  const tutorialImages = getTutorialImages();

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-[80vh] p-5 pt-[60px] text-center font-regular overflow-y-auto">
        {/* Language Switch */}
        <div className="absolute top-5 right-5 flex gap-2.5">
          <LanguageButton
            active={language === 'en'}
            onClick={() => handleLanguageSwitch('en')}>
            EN
          </LanguageButton>
          <LanguageButton
            active={language === 'zh'}
            onClick={() => handleLanguageSwitch('zh')}>
            简
          </LanguageButton>
          <LanguageButton
            active={language === 'zhhk'}
            onClick={() => handleLanguageSwitch('zhhk')}>
            繁
          </LanguageButton>
        </div>

        {/* Logo */}
        <img
          src="/assets/images/logo.png"
          alt="Carbon Point"
          className={`h-[50px] mb-5 transition-opacity duration-300 
            ${imagesLoaded ? 'opacity-100' : 'opacity-0'}`}
          onLoad={() => setImagesLoaded(true)}
        />

        {/* Main Content */}
        <div className="font-bold mb-5">{props.text ?? defaultProps.text}</div>
        <div className="font-regular mb-2.5">
          {props.subText ?? defaultProps.subText}
        </div>
        {error && <div className="text-red-500">{error}</div>}

        {/* Input Fields */}
        <input
          className="w-full h-10 my-2.5 px-4 border border-gray-300 rounded-[20px] bg-white font-light"
          type="text"
          placeholder={translate(language, 'login.userIdentifier')}
          value={userIdentifier}
          onChange={e => setUserIdentifier(e.target.value)}
        />
        <input
          className="w-full h-10 my-2.5 px-4 border border-gray-300 rounded-[20px] bg-white font-light"
          type="password"
          placeholder={props.userPassword ?? defaultProps.userPassword}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />

        {/* Remember Me */}
        <div className="self-start flex items-center mt-2.5">
          <input
            type="checkbox"
            id="rememberMe"
            checked={rememberMe}
            onChange={e => setRememberMe(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="rememberMe">
            {translate(language, 'login.rememberMe')}
          </label>
        </div>

        {/* Buttons */}
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <LoginButton
              onClick={handleLogin}
              text={translate(language, 'login.logIn')}
            />
            <LoginButton
              onClick={handleBiometricLogin}
              text={translate(language, 'login.biometricLogin')}
            />
          </>
        )}

        {/* Links */}
        <div
          className="text-blue-600 cursor-pointer mt-4 font-light"
          onClick={() =>
            navigate('/forgotPassword', {state: {showBackIcon: true}})
          }>
          {translate(language, 'login.forgotPassword')}
        </div>
        <div
          className="text-blue-600 cursor-pointer mt-4 font-light"
          onClick={() => navigate('/signup', {state: {showBackIcon: true}})}>
          {translate(language, 'login.notAMember')}
        </div>

        {/* Privacy Policy */}
        <a
          href="http://carbonpoint.io/privacy"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-5 left-1/2 -translate-x-1/2 text-gray-500 text-xs font-light no-underline hover:underline">
          {translate(language, 'login.privacyPolicy')}
        </a>
      </div>

      {/* Preloading tutorial images */}
      <div className="hidden">
        {tutorialImages.map(src => (
          <img key={src} src={src} alt="tutorial" />
        ))}
      </div>
    </>
  );
};

export default Login;
