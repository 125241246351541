import axios from 'axios';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const Explorer = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/partner/list`,
        );
        const partnersData = response.data;

        const groupedCategories = partnersData.reduce((acc, partner) => {
          const type = partner.type;
          if (!acc[type]) acc[type] = [];
          acc[type].push(partner);
          return acc;
        }, {});

        setCategories(groupedCategories);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching partners:', error);
        navigate('/login');
      }
    };

    fetchCategories();
  }, [navigate]);

  return (
    <>
      <TopNav text={translate(language, 'explorer.title')} />
      <div className="font-regular text-center text-gray-800 p-5 max-w-[400px] mx-auto bg-[#f1f6f8]">
        {/* Header */}
        <div className="mb-5 font-semibold">
          <h2>{translate(language, 'explorer.headerTitle')}</h2>
          <p>{translate(language, 'explorer.headerDescription')}</p>
        </div>

        {/* Categories */}
        {loading ? (
          <div className="grid grid-cols-2 gap-2.5 mb-5">
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="bg-white p-2.5 rounded-lg shadow-md flex flex-col items-center justify-center">
                  {/* Logo Skeleton */}
                  <div className="w-[100px] h-[80px] flex items-center justify-center mb-2.5">
                    <div className="w-[50px] h-[50px] rounded-full bg-gray-200 animate-pulse" />
                  </div>
                  {/* Name Skeleton */}
                  <div className="h-5 w-20 bg-gray-200 animate-pulse rounded" />
                </div>
              ))}
          </div>
        ) : (
          Object.keys(categories).map(categoryType => (
            <div key={categoryType}>
              {/* Category Header */}
              <div className="flex items-center my-5">
                <h3 className="mr-2.5 text-gray-400 capitalize">
                  {categoryType}
                </h3>
                <hr className="flex-grow border-t-2 border-gray-300" />
              </div>

              {/* Category Grid */}
              <div className="grid grid-cols-2 gap-2.5 mb-5">
                {categories[categoryType].map(partner => (
                  <div
                    key={partner._id}
                    onClick={() =>
                      navigate(`/partner/${partner._id}`, {
                        state: {showBackIcon: true},
                      })
                    }
                    className="bg-white p-2.5 rounded-lg shadow-md flex flex-col items-center justify-center cursor-pointer hover:shadow-lg transition-shadow duration-200">
                    <div className="w-[100px] h-[80px] flex items-center justify-center mb-2.5">
                      <img
                        src={partner.nameImg}
                        alt={partner.name[language]}
                        className="max-w-full max-h-full object-contain"
                      />
                    </div>
                    <span className="text-xs text-gray-800">
                      {partner.name[language]}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}

        {/* Footer */}
        <p className="mt-5 text-sm font-light">
          {translate(language, 'explorer.footerText')}
        </p>
      </div>
      <BottomNav />
    </>
  );
};

export default Explorer;
