import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const ImageSkeleton = () => (
  <div className="w-[335px] h-[230px] animate-pulse bg-gray-200 rounded-lg mx-auto" />
);

const ArticlePage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [userInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const {language} = useContext(LanguageContext);
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [liked, setLiked] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const fetchArticleDetail = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/article/detail/${id}`,
        );
        setArticle(response.data);

        const userId = userInfo._id;
        setLiked(response.data.likedUsers.includes(userId));
      } catch (err) {
        console.error('Error fetching article details:', err);
        setError(translate(language, 'articlePage.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchArticleDetail();
  }, [id, navigate, language]);

  const handleLike = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${id}`,
      );

      const userId = userInfo._id;
      setLiked(true);
      setArticle(prev => ({
        ...prev,
        likedUsers: [...prev.likedUsers, userId],
      }));
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${id}`,
      );

      const userId = userInfo._id;
      setLiked(false);
      setArticle(prev => ({
        ...prev,
        likedUsers: prev.likedUsers.filter(uid => uid !== userId),
      }));
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const likeIcon = liked ? (
    <FavoriteIcon
      className="text-primary cursor-pointer"
      onClick={handleUnlike}
    />
  ) : (
    <FavoriteBorderIcon
      className="text-primary cursor-pointer"
      onClick={handleLike}
    />
  );

  if (loading) {
    return (
      <div>
        <TopNav text={translate(language, 'articlePage.title')} />
        <div className="p-5 text-center">
          <h1>
            <div className="h-8 w-[200px] bg-gray-200 animate-pulse rounded-lg mx-auto" />
          </h1>

          <div className="h-[300px] w-full bg-gray-200 animate-pulse rounded-lg my-4" />

          <div className="flex justify-end items-center mt-2.5 space-x-2.5">
            <div className="h-10 w-[100px] bg-gray-200 animate-pulse rounded-lg" />
            <div className="h-10 w-[100px] bg-gray-200 animate-pulse rounded-lg" />
          </div>

          <div className="text-left mt-5 space-y-2">
            <div className="h-4 w-full bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[95%] bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-full bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[90%] bg-gray-200 animate-pulse rounded" />
            <div className="h-4 w-[85%] bg-gray-200 animate-pulse rounded" />
          </div>

          <div className="block mt-2.5">
            <div className="h-4 w-[150px] bg-gray-200 animate-pulse rounded mx-auto" />
          </div>
        </div>
        <BottomNav />
      </div>
    );
  }

  if (error) return <p>{translate(language, 'articlePage.error')}</p>;

  return (
    <div>
      <TopNav text={translate(language, 'articlePage.title')} />
      {article && (
        <div className="p-5 text-center">
          <h1 className="text-xl font-semibold mb-4">
            {article.title[language]}
          </h1>

          <div className="relative">
            {!imageLoaded && <ImageSkeleton />}
            <img
              src={article.coverImg[language]}
              alt="Cover"
              className={`w-[335px] h-[230px] rounded-lg shadow-md mx-auto object-cover ${!imageLoaded ? 'hidden' : ''}`}
              onLoad={() => setImageLoaded(true)}
            />
          </div>

          {/* Interaction Container */}
          <div className="flex justify-end items-center mt-2.5 space-x-2.5">
            <div className="flex items-center text-primary">
              <VisibilityIcon className="text-primary" />
              <span className="ml-1">{article.readUsers.length}</span>
            </div>
            <div className="flex items-center text-primary">
              {likeIcon}
              <span className="ml-1">{article.likedUsers.length}</span>
            </div>
          </div>

          {/* Article Content */}
          <div className="text-left mt-5 prose prose-sm max-w-none">
            <div
              dangerouslySetInnerHTML={{__html: article.content[language]}}
              className="article-content"
            />
          </div>

          {/* Date Posted */}
          <small className="block mt-2.5 text-gray-500">
            {translate(language, 'articlePage.postedOn')}:{' '}
            {new Date(article.created).toLocaleDateString()}
          </small>
        </div>
      )}
      <BottomNav />
    </div>
  );
};

export default ArticlePage;
