import {useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';
import BottomNav from '../Navigation/BottomNav';
import {validatePassword} from '../utils/validation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-family: ${props => props.fontFamily};
`;

const Section = styled.div`
  margin-bottom: 24px;
  padding: 16px;
  border: 1px solid #eee;
  border-radius: 12px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 16px;
  color: #333;
  font-size: 16px;
`;

const UserInput = styled.input`
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 0px 16px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${props => (props.disabled ? '#f5f5f5' : '#ffffff')};
  font-family: ${props => props.fontFamily};
`;

const Button = styled.button`
  width: 100%;
  height: 40px;
  margin-top: 12px;
  padding: 0px;
  border: none;
  border-radius: 20px;
  background-color: #3ddbd1;
  color: #ffffff;
  font-family: ${props => props.fontFamily};
  cursor: pointer;
`;

const ErrorText = styled.div`
  color: red;
  margin-top: 10px;
`;

const UserEdit = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);
  const {fontFamilies} = useFontFamilyByLanguage();

  const cookieUserInfo = Cookies.get('userInfo')
    ? JSON.parse(Cookies.get('userInfo'))
    : {};

  const [userInfo, setUserInfo] = useState({
    firstName: cookieUserInfo.firstName || '',
    lastName: cookieUserInfo.lastName || '',
    email: cookieUserInfo.email || '',
    phone: cookieUserInfo.phone || '',
    shortId: cookieUserInfo.shortId || '',
  });

  const [passwords, setPasswords] = useState({
    password: '',
    retypePassword: '',
  });

  const [error, setError] = useState({
    profile: '',
    password: '',
  });

  const handleUpdateProfile = async () => {
    // Validate name fields
    if (!userInfo.firstName || !userInfo.lastName) {
      setError(prev => ({
        ...prev,
        profile: translate(language, 'signup.firstNameRequired'),
      }));
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URI}/api/auth/updateUser`,
        {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true,
        },
      );
      setError(prev => ({...prev, profile: ''}));
      navigate('/profile');
    } catch (error) {
      console.error('Error updating user info:', error);
      setError(prev => ({
        ...prev,
        profile: translate(language, 'userEdit.error'),
      }));
    }
  };

  const handleUpdatePassword = async () => {
    // Validate password
    if (!validatePassword(passwords.password)) {
      setError(prev => ({
        ...prev,
        password: translate(language, 'signup.weakPassword'),
      }));
      return;
    }

    // Validate password match
    if (passwords.password !== passwords.retypePassword) {
      setError(prev => ({
        ...prev,
        password: translate(language, 'signup.passwordMismatch'),
      }));
      return;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_API_URI}/api/auth/updateUser`,
        {
          password: passwords.password,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          withCredentials: true,
        },
      );
      setError(prev => ({...prev, password: ''}));
      navigate('/profile');
    } catch (error) {
      console.error('Error updating password:', error);
      setError(prev => ({
        ...prev,
        password: translate(language, 'userEdit.error'),
      }));
    }
  };

  return (
    <>
      <TopNav text={translate(language, 'userEdit.title')} />
      <Container fontFamily={fontFamilies.regular}>
        <Section>
          <SectionTitle>
            {translate(language, 'userEdit.nameSection')}
          </SectionTitle>
          <UserInput
            fontFamily={fontFamilies.regular}
            type="text"
            placeholder={translate(language, 'signup.firstName')}
            value={userInfo.firstName}
            onChange={e =>
              setUserInfo({...userInfo, firstName: e.target.value})
            }
          />
          <UserInput
            fontFamily={fontFamilies.regular}
            type="text"
            placeholder={translate(language, 'signup.lastName')}
            value={userInfo.lastName}
            onChange={e => setUserInfo({...userInfo, lastName: e.target.value})}
          />
          {error.profile && <ErrorText>{error.profile}</ErrorText>}
          <Button
            fontFamily={fontFamilies.semiBold}
            onClick={handleUpdateProfile}>
            {translate(language, 'userEdit.updateProfile')}
          </Button>
        </Section>

        <Section>
          <SectionTitle>
            {translate(language, 'userEdit.passwordSection')}
          </SectionTitle>
          <UserInput
            fontFamily={fontFamilies.regular}
            type="password"
            placeholder={translate(language, 'signup.password')}
            value={passwords.password}
            onChange={e =>
              setPasswords({...passwords, password: e.target.value})
            }
          />
          <UserInput
            fontFamily={fontFamilies.regular}
            type="password"
            placeholder={translate(language, 'signup.retypePassword')}
            value={passwords.retypePassword}
            onChange={e =>
              setPasswords({...passwords, retypePassword: e.target.value})
            }
          />
          {error.password && <ErrorText>{error.password}</ErrorText>}
          <Button
            fontFamily={fontFamilies.semiBold}
            onClick={handleUpdatePassword}>
            {translate(language, 'userEdit.updatePassword')}
          </Button>
        </Section>
      </Container>
      <BottomNav />
    </>
  );
};

export default UserEdit;
