import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const BottomNav = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const {language} = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth / window.innerHeight < 1,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth / window.innerHeight < 1);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const NavIcon = ({children, path, label}) => (
    <div
      className="flex flex-col items-center justify-center cursor-pointer"
      onClick={() => navigate(path)}>
      <svg
        className={`w-[35px] h-[30px] opacity-50 pb-[6px] ${location.pathname === path ? 'text-[#3DDBD1]' : 'text-[#26454a]'}`}
        viewBox="0 0 24 24">
        {children}
      </svg>
      <span
        className={`text-[10px] font-light ${location.pathname === path ? 'text-[#3DDBD1]' : 'text-[#26454a]'}`}>
        {translate(language, label)}
      </span>
    </div>
  );

  return (
    <>
      <div className="h-[120px]" />
      <div
        className={`
        fixed bottom-0 left-0 right-0 
        h-[76px] min-w-[300px] 
        bg-white border-t border-[#f0f0f0] 
        shadow-[0px_2px_8px_rgba(0,0,0,0.16)] 
        z-[40] 
        flex items-center justify-between
        ${isMobile ? 'flex' : 'hidden'}
      `}>
        {props.children}
        <div />

        <NavIcon path="/" label="bottomNav.home">
          <path d="m12 5.69 5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3 2 12h3v8h6v-6h2v6h6v-8h3L12 3z" />
        </NavIcon>

        <NavIcon path="/explorer" label="bottomNav.explorer">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-5.5-2.5 7.51-3.49L17.5 6.5 9.99 9.99 6.5 17.5zm5.5-6.6c.61 0 1.1.49 1.1 1.1s-.49 1.1-1.1 1.1-1.1-.49-1.1-1.1.49-1.1 1.1-1.1z" />
        </NavIcon>

        <div
          className="flex flex-col items-center justify-center cursor-pointer"
          onClick={() => navigate('/profile')}>
          <div
            className="mt-[-32px] w-[80px] h-[60px] rounded-none bg-center bg-cover bg-no-repeat z-[1]"
            style={{
              backgroundImage: `url(${props.image ?? '/assets/icons/buttonIcon.png'})`,
            }}
          />
          <span
            className={`text-[10px] font-light mt-[6px] ${location.pathname === '/profile' ? 'text-[#3DDBD1]' : 'text-[#26454a]'}`}>
            {translate(language, 'bottomNav.profile')}
          </span>
        </div>

        <NavIcon path="/redeem" label="bottomNav.redeem">
          <path d="M20 6h-2.18c.11-.31.18-.65.18-1a2.996 2.996 0 0 0-5.5-1.65l-.5.67-.5-.68C10.96 2.54 10.05 2 9 2 7.34 2 6 3.34 6 5c0 .35.07.69.18 1H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM9 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15H4v-2h16v2zm0-5H4V8h5.08L7 10.83 8.62 12 11 8.76l1-1.36 1 1.36L15.38 12 17 10.83 14.92 8H20v6z" />
        </NavIcon>

        <NavIcon path="/saved" label="bottomNav.saved">
          <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
        </NavIcon>

        <div />
      </div>
    </>
  );
};

export default BottomNav;
