import axios from 'axios';
import Cookies from 'js-cookie';
import {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ReactSVG} from 'react-svg';
import BottomNav from '../Navigation/BottomNav';
import TopNav from '../Navigation/TopNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {translate} from '../utils/translate';

const Points = () => {
  const navigate = useNavigate();
  const {language} = useContext(LanguageContext);

  const [userInfo, setUserInfo] = useState(
    Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : {},
  );
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Poll for changes in the cookie every second
  useEffect(() => {
    const interval = setInterval(() => {
      const updatedUserInfo = Cookies.get('userInfo')
        ? JSON.parse(Cookies.get('userInfo'))
        : {};
      if (JSON.stringify(updatedUserInfo) !== JSON.stringify(userInfo)) {
        setUserInfo(updatedUserInfo);
      }
    }, 1000); // Poll every second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [userInfo]);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/transaction/listMyTransactions`);
        if (response.data && Array.isArray(response.data)) {
          const processedTransactions = response.data
            .filter(
              transaction => transaction && typeof transaction === 'object',
            ) // Ensure each transaction is an object
            .map(transaction => {
              if (transaction.type === 'Transfer - Debit') {
                return {
                  ...transaction,
                  itemSnapshot: {
                    name: {
                      [language]: translate(language, 'points.transferDebit'),
                    },
                    description: {
                      [language]: translate(
                        language,
                        'points.transferDebitDescription',
                      ),
                    },
                  },
                };
              } else if (transaction.type === 'Transfer - Credit') {
                return {
                  ...transaction,
                  itemSnapshot: {
                    name: {
                      [language]: translate(language, 'points.transferCredit'),
                    },
                    description: {
                      [language]: translate(
                        language,
                        'points.transferCreditDescription',
                      ),
                    },
                  },
                };
              }
              return transaction;
            })
            .filter(
              transaction =>
                transaction.type?.startsWith('Transfer - ') ||
                (transaction.itemSnapshot?.name?.[language] &&
                  transaction.itemSnapshot?.description?.[language]),
            );

          setTransactions(processedTransactions);

          console.log(transactions);
        }
      } catch (error) {
        console.error('Failed to fetch transactions:', error);
        setError(translate(language, 'points.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [navigate, language]);

  return (
    <>
      <TopNav text={translate(language, 'points.myPoints')} />
      {/* Top Container */}
      <div className="flex items-center justify-center fixed w-full bg-primary -mt-5 pt-5 rounded-[20px] z-50">
        <div className="p-5 w-[40%] -mb-5 bg-white rounded-[20px] text-xs font-regular shadow-sm">
          {loading ? (
            <>
              {/* Points Balance Skeleton */}
              <div className="space-y-2">
                <div className="h-4 w-[100px] bg-gray-200 animate-pulse rounded mx-auto" />
                <div className="h-6 w-[50px] bg-gray-200 animate-pulse rounded mx-auto" />
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center justify-center">
                {translate(language, 'points.pointsBalance')}
              </div>
              <div className="flex items-center justify-center text-lg font-semibold">
                <ReactSVG
                  src="/assets/icons/coins.svg"
                  className="h-[25px] w-[25px] mr-[5px]"
                />
                {userInfo.points}
              </div>
            </>
          )}
        </div>
      </div>

      {/* Transfer Button */}
      <button
        onClick={() => navigate(`/transfer`, {state: {showBackIcon: true}})}
        className="mx-auto mt-[100px] block w-[60%] py-2.5 px-5 bg-primary text-white border-none rounded-[10px] text-base font-semibold cursor-pointer text-center shadow-sm hover:bg-[#33c4b9]">
        {translate(language, 'points.transfer')}
      </button>

      {/* Transactions Container */}
      <div className="mt-5 p-5">
        {loading ? (
          <>
            {[1, 2, 3].map((_, index) => (
              <div key={index} className="mb-5">
                <div className="h-[80px] bg-gray-200 animate-pulse rounded-[20px]" />
              </div>
            ))}
          </>
        ) : error ? (
          <p>{error}</p>
        ) : transactions.length > 0 ? (
          transactions.map(transaction => (
            <div
              key={transaction.id}
              className="m-5 p-5 bg-white rounded-[20px] shadow-sm">
              <div className="flex justify-between items-center w-full">
                <div className="text-xs font-semibold">
                  {transaction.itemSnapshot.name[language]}
                </div>
                <div className="text-xs font-semibold">
                  {transaction.points} {translate(language, 'points.points')}
                </div>
              </div>
              <div className="text-xs font-light">
                {new Date(transaction.created).toLocaleString()}
              </div>
              <div className="text-xs font-regular">
                {transaction.itemSnapshot.description[language]}
              </div>
            </div>
          ))
        ) : (
          <p>{translate(language, 'points.noTransactions')}</p>
        )}
      </div>
      <BottomNav />
    </>
  );
};

export default Points;
